<template>
  <div class="edit-organisation">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Edit Organisation</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Update</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <ValidationObserver ref="editOrganisation" v-slot="{ handleSubmit }">
          <form id="editOrganisation" @submit.prevent="handleSubmit(onSubmit)">
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Organisation Type</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation_type_id"
                        :value="organisation.organisation_type_id"
                        :options="
                          options && options['organization_type']
                            ? options['organization_type']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                        :disabled="isAdvertiser"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="institution_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Institution Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="name"
                        :value="organisation.name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="address" class="mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Description
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="description"
                        :value="organisation.description"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3" name="country">
                  <label class="required col-lg-12 col-md-12">Country</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="country_ids"
                        :value="organisation.country_ids"
                        :options="
                          options && options['country']
                            ? options['country']
                            : []
                        "
                        :multiple="true"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label name="location" class="required col-lg-12 col-md-12"
                    >City</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="location_ids"
                        :value="organisation.location_ids"
                        :options="
                          options && options['location']
                            ? options['location']
                            : []
                        "
                        :multiple="true"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="mb-3">
                  <label name="location" class="col-lg-12 col-md-12"
                    >Districts</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="district_ids"
                      :value="organisation.district_ids"
                      :options="
                        options && options['districts']
                          ? options['districts']
                          : []
                      "
                      :multiple="true"
                      :clearable="false"
                      @input="handleChangeSelect"
                      :disabled="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  numeric,
} from "vee-validate/dist/rules";
import { mapGetters, mapActions } from "vuex";

extend("required", { ...required, message: "This field is required" });
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("email", { ...email, message: "Invalid email" });
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  props: {
    organisationDetail: {
      type: Object,
      default: () => null,
    },
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    RadioButton,
  },
  data() {
    return {
      organisation: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters([
      "isFetchingFilter",
      "locationCountryInfo",
      "dialingCodeWithCountryCode",
      "getOrganisationTypeList",
      "licenseIssuedByCountry",
      "getLocationByCountryID",
    ]),
    options() {
      return {
        country: this.locationCountryInfo || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        location:
          this.getLocationByCountryID({
            country_id: this.organisation?.country_id?.code,
          }) || [],
        is_parent_admin: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        organization_type: this.getOrganisationTypeList || [],
        license_authority:
          this.licenseIssuedByCountry({
            country_id: this.organisation?.country_id?.code,
          }) || [],
      };
    },
    isAdvertiser() {
      // 4 - Advertiser
      return this.organisation?.organisation_type_id?.code == 4 ? true : false;
    }
  },
  methods: {
    ...mapActions(["initOrganisationFetchOptions"]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.organisation, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.organisation, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name === "country_id") {
        this.handleChangeSelect("location_id", null);
      }
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    cancelUpdate() {
      this.$router.push({ path: `/customer-list` });
    },
    async onSubmit() {
      const isValid = await this.$refs.editOrganisation.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        organisation_id: this.organisation?.organisation_id,
        organisation_type_id: this.organisation?.organisation_type_id?.code,
        name: this.organisation?.name,
        description: this.organisation?.description,
        ...this.payload,
      };
      this.modalCallBack(true, finalPayload);
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
  mounted() {
    this.initOrganisationFetchOptions();
    const organisationData = this.organisationDetail;
    this.organisation = {
      organisation_id: organisationData?.organisation_id,
      organisation_type_id: {
        code:
          organisationData?.organisation?.organisation_type
            ?.organisation_type_id,
        label: organisationData?.organisation?.organisation_type?.comments,
      },
      name: organisationData?.organisation?.name,
      description: organisationData?.organisation?.description,
      country_ids: organisationData?.customer_organisation_location
        ? _.uniqBy(
            organisationData?.customer_organisation_location.map((val) => ({
              code: val?.location?.country?.country_id,
              label: val?.location?.country?.country_name,
            })),
            "code"
          )
        : [],
      location_ids: organisationData?.customer_organisation_location
        ? _.uniqBy(
            organisationData?.customer_organisation_location.map((val) => ({
              code: val?.location?.location_id,
              label: val?.location?.location,
            })),
            "code"
          )
        : [],
      district_ids: organisationData?.customer_organisation_location
        ? _.uniqBy(
            organisationData?.customer_organisation_location
              .map((val) =>
                val?.district
                  ? {
                      code: val?.district_id,
                      label: val?.district?.district,
                    }
                  : null
              )
              .filter((v) => v),
            "code"
          )
        : [],
      issue_auth_id: {
        code: organisationData?.organisation?.license_auth?.issue_authority_id,
        label: organisationData?.organisation?.license_auth?.authority_name,
      },
      first_name: organisationData?.organisation?.main_contact?.name.split(
        " "
      )[0],
      last_name: organisationData?.organisation?.main_contact?.name.split(
        " "
      )[1],
      phone: organisationData?.organisation?.main_contact?.phone_no,
      email: organisationData?.organisation?.main_contact?.email,
    };
  },
};
</script>