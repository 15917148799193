<template>
  <div class="container-md col-lg-10 col-md-10 pt-2 organisation-list">
    <LoadingOverlay v-if="isCustomerFetch" />
    <div class="pb-5 mb-2" v-else>
      <h3 class="text-primary m-1 pt-1 pb-2">
        Organisation List
        <span class="nowrap" style="font-size: 20px;"
          >({{ organisationList.length || "0" }} Record{{
            organisationList.length > 1 ? "s" : ""
          }})</span
        >
      </h3>
      <div v-if="!isUKMainAgency">
        <CDataTable
          :striped="striped"
          :bordered="bordered"
          :small="small"
          :items="organisations"
          :fields="fields"
          :items-per-page="small ? limit : 5"
          sorter
          :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
          pagination
          class="border px-1"
        >
          <template #action="{ item, index }">
            <td class="py-2">
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                v-c-tooltip="'Edit'"
                @click="editOrganisation(item, index)"
                ><i class="far fa-edit"></i
              ></CButton>
            </td>
          </template>
        </CDataTable>
      </div>
      <div v-else>
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="'Partner Hospitals' + ` (${partnerHospitals.length}) `">
            <CDataTable
              :striped="striped"
              :bordered="bordered"
              :small="small"
              :items="partnerHospitals"
              :fields="fields"
              :items-per-page="small ? limit : 5"
              sorter
              :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
              pagination
              class="p-2 border"
            >
              <template #action="{ item, index }">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="'Edit'"
                    @click="editOrganisation(item, index)"
                    ><i class="far fa-edit"></i
                  ></CButton>
                </td>
              </template>
            </CDataTable>
          </CTab>
          <CTab :title="'Partner Agencies' + ` (${partnerAgencies.length}) `">
            <CDataTable
              :striped="striped"
              :bordered="bordered"
              :small="small"
              :items="partnerAgencies"
              :fields="fields"
              :items-per-page="small ? limit : 5"
              sorter
              :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
              pagination
              class="p-2 border"
            >
              <template #action="{ item, index }">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="'Edit'"
                    @click="editOrganisation(item, index)"
                    ><i class="far fa-edit"></i
                  ></CButton>
                </td>
              </template>
            </CDataTable>
          </CTab>
          <CTab :title="`Educational institution (${educationalInstitution.length})`">
            <CDataTable
              :striped="striped"
              :bordered="bordered"
              :small="small"
              :items="educationalInstitution"
              :fields="fields"
              :items-per-page="small ? limit : 5"
              sorter
              :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
              pagination
              class="p-2 border"
            >
              <template #action="{ item, index }">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="'Edit'"
                    @click="editOrganisation(item, index)"
                    ><i class="far fa-edit"></i
                  ></CButton>
                </td>
              </template>
            </CDataTable>
          </CTab>
          <CTab :title="`Training Course (${trainingCourse.length})`">
            <CDataTable
              :striped="striped"
              :bordered="bordered"
              :small="small"
              :items="trainingCourse"
              :fields="fields"
              :items-per-page="small ? limit : 5"
              sorter
              :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
              pagination
              class="p-2 border"
            >
              <template #action="{ item, index }">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="'Edit'"
                    @click="editOrganisation(item, index)"
                    ><i class="far fa-edit"></i
                  ></CButton>
                </td>
              </template>
            </CDataTable>
          </CTab>
          <CTab :title="`Visa Licensing (${visaLicensing.length})`">
            <CDataTable
              :striped="striped"
              :bordered="bordered"
              :small="small"
              :items="visaLicensing"
              :fields="fields"
              :items-per-page="small ? limit : 5"
              sorter
              :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
              pagination
              class="p-2 border"
            >
              <template #action="{ item, index }">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="'Edit'"
                    @click="editOrganisation(item, index)"
                    ><i class="far fa-edit"></i
                  ></CButton>
                </td>
              </template>
            </CDataTable>
          </CTab>
          <CTab :title="'Others' + ` (${otherOrganisations.length}) `">
            <CDataTable
              :striped="striped"
              :bordered="bordered"
              :small="small"
              :items="otherOrganisations"
              :fields="fields"
              :items-per-page="small ? limit : 5"
              sorter
              :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
              pagination
              class="p-2 border"
            >
              <template #action="{ item, index }">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="'Edit'"
                    @click="editOrganisation(item, index)"
                    ><i class="far fa-edit"></i
                  ></CButton>
                </td>
              </template>
            </CDataTable>
          </CTab>
        </CTabs>
      </div>
    </div>
    <OrganisationEdit
      v-if="organisationEditModal.isShowPopup"
      :organisationDetail="organisationEditModal.organisation"
      :isShowPopup="organisationEditModal.isShowPopup"
      @modalCallBack="organisationModalCallBack"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import OrganisationEdit from "@/containers/Organisation/EditOrganisation";
export default {
  components: {
    OrganisationEdit,
  },
  data() {
    return {
      limit: 5,
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      fields: [
        {
          key: "organisation_name",
          label: "Organisation Name",
          _style: "width:25%",
        },
        {
          key: "path_acronym",
          label: "Path Acronym",
          _style: "width:10%",
        },
        {
          key: "organisation_type",
          label: "Organisation Type",
          _style: "width:15%",
        },
        {
          key: "city",
          _style: "width:10%",
        },
        {
          key: "main_contact_name",
          label: "Main Contact Name",
          _style: "width:15%;white-space:nowrap",
        },
        { key: "email", label: "Email", _style: "width:15%" },
        {
          key: "action",
          label: "Action",
          sorter: false,
          filter: false,
          _style: "width:10%",
        },
      ],
      organisationEditModal: {
        isShowPopup: false,
        organisation: null,
        organisationID: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "organisationList",
      "isCustomerFetch",
      "getUser",
      "isUKMainAgency", // pertemps
    ]),
    organisations() {
      return (
        (this.organisationList &&
          this.organisationList.length &&
          this.organisationList
            .map(({ organisation, ...rest }) => ({
              organisation_name: organisation?.name,
              organisation_type:
                organisation?.organisation_type?.organisation_type,
              organisation_type_id:
                organisation?.organisation_type?.organisation_type_id,
              main_contact_name: organisation?.main_contact?.name,
              email: organisation?.main_contact?.email,
              organisation_id: organisation?.organisation_id,
              city: rest?.customer_organisation_location
                ? rest?.customer_organisation_location
                    .map((val) => val?.location?.location)
                    .join()
                : "--",
              path_acronym: rest?.path_acronym || '--',
            }))
            .reverse()) ||
        []
      );
    },
    partnerHospitals() {
      return (
        (this.organisations &&
          this.organisations.length &&
          this.organisations.filter(
            ({ organisation_type_id }) => organisation_type_id === 16
          )) ||
        []
      );
    },
    partnerAgencies() {
      return (
        (this.organisations &&
          this.organisations.length &&
          this.organisations.filter(
            ({ organisation_type_id }) => organisation_type_id === 13
          )) ||
        []
      );
    },
    educationalInstitution() {
      return (
        (this.organisations &&
          this.organisations.length &&
          this.organisations.filter(
            ({ organisation_type_id }) => organisation_type_id === 18
          )) ||
        []
      );
    },
    trainingCourse() {
      return (
        (this.organisations &&
          this.organisations.length &&
          this.organisations.filter(
            ({ organisation_type_id }) => organisation_type_id === 20
          )) ||
        []
      );
    },
    visaLicensing() {
      return (
        (this.organisations &&
          this.organisations.length &&
          this.organisations.filter(
            ({ organisation_type_id }) => organisation_type_id === 21
          )) ||
        []
      );
    },
    otherOrganisations() {
      return (
        (this.organisations && 
          this.organisations.length &&
          this.organisations.filter(
            ({ organisation_type_id }) => ![13, 16, 18, 20, 21].includes(organisation_type_id))
        ) || []
      );
    },
  },
  methods: {
    ...mapActions(["fetchOrganisationList", "fetchUser", "updateOrganisation"]),
    editOrganisation(item, index) {
      this.tooltipHide();
      const organisationData = this.organisationList[
        this.organisationList.findIndex(
          (e) => e.organisation_id === item.organisation_id
        )
      ];
      this.organisationEditModal["isShowPopup"] = true;
      this.organisationEditModal["organisation"] = organisationData;
      this.organisationEditModal["organisationID"] = item.organisation_id;
    },
    organisationModalCallBack(action, payload) {
      if (action === false) {
        this.organisationEditModal.isShowPopup = false;
        this.organisationEditModal.organisation = null;
        this.organisationEditModal.organisationID = null;
      } else if (action === true) {
        let appendAction = this.updateOrganisation(payload);
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.organisationEditModal.isShowPopup = false;
            this.organisationEditModal.organisation = null;
            this.organisationEditModal.organisationID = null;
            this.fetchOrganisationList();
          }
        });
      }
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
  },
  mounted() {
    let appendAction = [];
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then((res) => {
      this.fetchOrganisationList();
    });
  },
};
</script>
